import { useFintalkCloud } from "contexts/FintalkCloud/fintalkCloudContext";

export function BubbleModule() {
  const { currentData } = useFintalkCloud();
  const token = localStorage.getItem('token');

  const injectToken = () => {
    const checkAndInject = () => {
      const iframe = document.getElementById('bubble-iframe') as HTMLIFrameElement;
      if (iframe?.contentWindow) {
        iframe.contentWindow.postMessage({
          type: 'setToken',
          token: token
        }, '*');
        console.log('Token message sent to iframe');
      } else {
        setTimeout(checkAndInject, 100);
      }
    };
    checkAndInject();
  };

  return (
    <iframe
      id="bubble-iframe"
      title="Bubble"
      src={`https://bubble-front.run.fintalk.io/${currentData.agentName}/bubbles`}
      width="100%"
      height="100%"
      onLoad={injectToken}
    ></iframe>
  );
}